.order__bottomBar {
}

.order__bottomBar-button {
  width: 95vw;
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.order__bottomBar-content {
  display: flex;
  justify-content: space-between;

  width: 100%;
}
