.odr__product {
  padding: 10px;
}

.ordering__product-container {
  margin-bottom: 2rem;
  padding: 1rem 0;
  border-top: 1px solid #e4e4e4;
}

.ordering__product-container-top_info {
  display: flex;
  align-items: flex-start;
}

.ordering__product-container-top_info-details {
  width: 70%;
}

.ordering__product-container-top_info-image {
  width: 30%;
  height: 100px;
}

.ordering__product-container-top_info-image img {
  width: 100%;
  height: 100%;
}

.ordering__product-addToButton {
  display: flex;
  justify-content: center;
}

.ordering__product-editButtons {
  display: flex;

  justify-content: center;
}
