.order__success {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.order__success-btn {
  width: 100%;
}
