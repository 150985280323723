.ordering__horiScrollBar {
  display: flex;
  align-items: center;
  background-color: white;
  height: 50px;
  width: 100vw;
  margin: 0 auto;
  position: sticky;
  overflow-x: auto;
  z-index: 997;
  top: 0;
  transition: box-shadow 0.15s ease-in-out;
  box-shadow: 0 1px #0000001f;
}

.ordering__horiScrollBar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  height: 30px;
  position: sticky;
  z-index: 997;
  top: 0;
}
