.oder__productModal-Background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.oder__productModal-Container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 10000;
}

.odr__productModal-Container_CloseBtn {
  display: flex;
  justify-content: flex-end;
}

.odr__productModal-Container_cardActions {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.odr__productModal-Container_cardActions1 {
  display: flex;
  width: 35%;
  justify-content: space-between;
  border: 1px solid #1976d2;
  padding: 5px 10px;
  border-radius: 10px;
}
