.oder__cartmodal-Background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.oder__cartmodal-container {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  min-height: 500px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 10000;
}
.odr__cartmodal-Container_CloseBtn {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}

.odr__cartmodal-Container_product {
  margin: 0 0 5rem 0;
}

.odr__cartmodal-product-headingDetails {
  display: flex;
  justify-content: space-between;
}
.odr__cartmodal-product {
  border-top: 1px solid rgba(32, 33, 37, 0.3);
  margin-bottom: 2rem;
}

.odr__cartmodal-Container_product-actions {
  display: flex;
  justify-content: space-between;
}

.odr__cartmodal-Container_product-actions_1 {
  display: flex;
  align-items: center;
}
.odr__cartmodal-Container_button {
  position: fixed;
  bottom: 10px;
  width: 95%;
  margin: 0 auto;
  left: 0px;
  right: 0px;
}

.odr__cartmodal-Container_bottombar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cartModalPadding {
  padding: 5px 10px;
}
