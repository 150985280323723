.order__checkout {
}

.order__checkout-inputContainer {
  margin-bottom: 3rem;
}

.order__checkout-inputContainer label {
  margin-bottom: 5px;
}
.order__checkout-inputContainer_item {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding: 0 10px;
}

.order__checkout-inputContainer_item label,
.order__checkout-inputContainer_item textarea {
  font-family: var(--font-family);
}

.order__checkout-inputContainer_item input {
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgb(196, 195, 195);
  font-family: var(--font-family);
}

.order__checkout-inputContainer_item textarea {
  border-radius: 5px;
  border: 1px solid rgb(196, 195, 195);
}

input[type="text"],
input[type="number"],
textarea {
  font-size: 16px;
}

.order__checkout-moreItems {
  border-top: 1px solid rgba(32, 33, 37, 0.3);
  border-bottom: 1px solid rgba(32, 33, 37, 0.3);
  padding: 10px 0;
  margin-bottom: 2rem;
}

.order__checkout-containerPrice {
  padding: 10px;
}
.order__checkout-moreItems {
  display: flex;
  justify-content: center;
}

.order__checkout-price {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.order__checkout-payBtn {
  margin: 2rem auto;
  width: 95%;
}
